// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getNotification = createAsyncThunk('notifications/getNotification', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/notifications/getAll', {
    params
  })
  return {
    params,
    data: response.data.data.notifications,
    totalPages: response.data?.total,
    un_read: response.data.data.unread_notifications_count,
    loading: true
  }
})

export const getAllNotification = createAsyncThunk('notifications/getAllNotification', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/notifications/getAll', {
    params
  })
  return {
    allData: response.data.data.notifications?.data,
    totalPages: response.data.data.notifications?.total,
    loading: true
  }
})

export const getUsers = createAsyncThunk('notifications/getUsers', async params => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/users?with_pagination=no'
  )
  return response.data.data
})

export const resetUser = createAsyncThunk('segment/resetUser', () => {
  return null
})

export const addNotification = createAsyncThunk(
  'notifications/addNotification',
  async (user, { dispatch, getState }) => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/notifications/notify_all', user, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(res => {
        successAlert()
      })
      .catch(err => {
        if (err?.response?.data?.message) {
          errorAlert(err?.response?.data?.message)
        } else {
          errorAlert(err)
        }
      })
    await dispatch(getNotification(getState().notifications.params))
    return user
  }
)
export const addNotificationUser = createAsyncThunk(
  'notifications/addNotificationUser',
  async (params, { dispatch, getState }) => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + `/api/dashboard/superAdmin/notifications/notify/${params.id}`,
        params.data,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      .then(res => {
        successAlert()
      })
      .catch(err => {
        if (err?.response?.data?.message) {
          errorAlert(err?.response?.data?.message)
        } else {
          errorAlert(err)
        }
      })
    await dispatch(getNotification(getState().notifications.params))
    return params.id
  }
)

export const readNotification = createAsyncThunk(
  'notifications/readNotification',
  async (id, { dispatch, getState }) => {
    await axios
      .get(`https://driver.bw-businessworld.com/api/v1/web/notification/${id}/show`)
      .then(res => {
        // successAlert()
      })
      .catch(err => {
        console.log(err)
      })
    await dispatch(getNotification(getState().notifications.params))
    return id
  }
)
export const updateNotifications = createAsyncThunk(
  'notifications/updateNotifications',
  async (id, { dispatch, getState }) => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/superAdmin/notifications/markAsRead`)
      .then(res => {})
      .catch(err => {
        console.log(err)
      })
    await dispatch(getNotification(getState().notifications.params))
  }
)
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    users: [],
    un_read: null,
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getNotification.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
        state.un_read = action.payload.un_read
      })
      .addCase(getAllNotification.fulfilled, (state, action) => {
        state.allData = action.payload.allData
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default notificationsSlice.reducer
